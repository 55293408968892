import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Home from '../web/views/home';
import Productview from '../web/views/product';
import Singleproduct from './views/single-product';
import Shopdetails from './views/shop-details';
import PrivateRoute from '../PrivateRoute';
import Checkout from './views/checkout';
import Login from './views/checkout/login';
import Register from './views/checkout/register';
import NotFound from '../../NotFound';
import Complete from './views/checkout/complete';
import Account from './views/account';
import Failed from './views/checkout/failed';
import AboutUs from '../../components/aboutus';
import ContactUs from '../../components/contactus';
import Sitemap from '../../components/sitemap';
import PrivacyPolicy from '../../components/privacypolicy';
import TermsAndConditions from '../../components/termsandconditions';
import Wishlist from './views/account/wishlist';
import Pricing from '../../components/pricing';
import ReturnsAndCancellation from '../returnsandcancelations';
import tagproduct from './views/tagproduct';
import Search from './views/home/search';

// Function to check and authenticate from query parameters
const authenticateFromQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    
    if (token && email) {
        // Store token and email in sessionStorage
        sessionStorage.setItem('_sid', token);
        sessionStorage.setItem('email', email);

        // Clear the query parameters from the URL (update history without reloading the page)
        const cleanUrl = window.location.pathname;
        window.history.replaceState(null, '', cleanUrl);  // Removes the query params

    }
};

class RootRoutes extends Component {
    constructor(props) {
        super(props);
        // Check and authenticate from query parameters immediately
        authenticateFromQueryParams();
    }

    render() {
        const { location } = this.props;
        const shouldShowSearch = !(location.pathname === '/' || location.pathname.startsWith('/account') || location.pathname.startsWith('/tagsearch'));

        return (
            <div>
                <Header />
                {shouldShowSearch && <Search />} {/* Render Search only if not on Home */}
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/p/:id' component={Singleproduct} />
                    <Route exact path='/tagsearch/:tag' component={tagproduct} />
                    <Route exact path='/shop/:slug' component={Shopdetails} />
                    <PrivateRoute path='/checkout' component={Checkout} />
                    <Route path='/product/catalogsearch/result' component={Productview} />
                    <PrivateRoute path='/order/success' component={Complete} />
                    <PrivateRoute path='/order/failed' component={Failed} />
                    <Route exact path='/wishlist' component={Wishlist} />
                    <PrivateRoute path='/account' component={Account} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/register' component={Register} />
                    <Route exact path='/about-us' component={AboutUs} />
                    <Route exact path='/contact-us' component={ContactUs} />
                    <Route exact path='/sitemap' component={Sitemap} /> {/* Add this line */}
                    <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/returnsandcancellation" component={ReturnsAndCancellation} />
                    <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
                    <Route component={NotFound} />

                    {/* Dynamic Route for Category or Subcategory */}
                    <Route
                        path="/products"
                        render={(props) => {
                            const queryParams = new URLSearchParams(props.location.search);
                            const categoryId = queryParams.get('categoryId');
                            const subcategoryId = queryParams.get('subcategoryId');
                            return (
                                <Productview
                                    categoryId={categoryId}
                                    subcategoryId={subcategoryId}
                                    {...props}
                                />
                            );
                        }}
                    />

                    {/* Catch-all Route for 404 */}
                    <Route path="*" component={() => <div>404 Not Found</div>} />
                </Switch>
                <Footer />
            </div>
        );
    }
}

// Export the component wrapped with withRouter to get access to location
export default withRouter(RootRoutes);
